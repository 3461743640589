<template>
  <van-tabbar route active-color="#EB8204">
    <van-tabbar-item icon="wap-home" to="/bucket/">首页</van-tabbar-item>
    <van-tabbar-item icon="apps-o" to="/bucket/list">设备</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/bucket/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabbar'
}
</script>
