<template>
  <div class="content">
    <div class="banner">
        <div class="hint">
            <div><img src="@/assets/bucket/img/login-logo.png" width="110px" height="110px"></div>
            <div>欢迎使用安全哨卫系统</div>
        </div>
    </div>
    <div class="info">
      <div class="inner">
          <van-cell title="产品介绍" is-link @click="forward" />
          <van-cell title="版本号" value="v1.0.0" @click="hint"/>
      </div>
    </div>
    <tabBar />
  </div>
</template>
<script>
import tabBar from '@/components/bucket/tabBar.vue'
export default {
  components: {
    tabBar
  },
  methods:{
    hint(){
      this.$toast('当前是最新版本')
    },
    forward(){
      this.$toast('敬请期待')
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  height: 15rem;
  text-align: center;
  .hint{
    color: white;font-size: 1.35rem;padding: 15% 0 20% 0;
    font-weight: bolder;
  }
}
.info{
  padding:0 4%;
  text-align: left;
  .inner{
  padding:0 2%;background-color: white;border-radius:10px;
  .van-cell{padding: 16px}
  .van-cell__title{font-weight: bold;color: #363636;}
}
}


</style>